import React, { Component } from 'react';
import Header from './Header';
import '../scss/app.scss';
import DevTenantProvider from "./TenantContext/DevTenantProvider";
import AppRouter from "./AppRouter";
import { JL } from 'jsnlog';
import RouterLogger from "./RouterLogger";
import TenantProvider from "./TenantContext/TenantProvider";
import TenantFeatureTogglesProvider from './FeatureToggles/TenantFeatureTogglesProvider';
import allTenants from './TenantContext/allTenants';
import withTenantConfiguration from '../locales/withTenantConfiguration';
import {Helmet} from 'react-helmet';

const supportedTenants = ['dk', 'ca', 'uk', 'ie', 'es', 'fr', 'it'];
const selectorTenants = allTenants.filter(t => supportedTenants.includes(t.tenant));

class App extends Component {
	componentDidCatch(error, info) {
		JL().error(error);
		JL().error(info);
	}


	render() {
		const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
        const STenantProvider = isDevelopment ? DevTenantProvider : TenantProvider;
		return (
			<React.Fragment>
				<RouterLogger />

                <STenantProvider tenants={selectorTenants}>
                    <InnerApp />
				</STenantProvider>
			</React.Fragment>
		);
	}
}

const InnerApp = withTenantConfiguration('app', (props) => <React.Fragment>
    <Helmet htmlAttributes={{ lang : props.activeLanguage }}>
        <title>{props.t('title')}</title>
    </Helmet>

    <Header/>

    <TenantFeatureTogglesProvider>
        <AppRouter />
    </TenantFeatureTogglesProvider>
</React.Fragment>);

export default App;
